<template>
  <div class="layout is-relative">
    <header-app :sticky="scrolled"/>
    <nav-app />
    <main class="main">
      <router-view></router-view>
    </main>
    <footer-app/>
  </div>
</template>

<script>
import HeaderApp from "@/components/general/Header"
import FooterApp from "@/components/general/Footer"
import NavApp from "@/components/general/NavApp"
import { onMounted, ref } from "vue";

export default {
  components: {
    HeaderApp,
    FooterApp,
    NavApp,
  },
  setup() {

    const scrolled = ref(false)
    const limitPosition = ref(20)


    const onScrollHandle = () => {

       if (limitPosition.value > window.scrollY) {
         scrolled.value = false;
         // move down
       } else {
          scrolled.value = true;
       }

    }

    onMounted(() => {
      document.body.classList.add("has-navbar-fixed-top");
      window.addEventListener("scroll", onScrollHandle );
    })

    return {
      scrolled
    }
  }
}
</script>
<style lang="scss" scoped>

  @media screen and (min-width: 992px) {
    .layout {
      padding-left: 60px;
       padding-right: 20px;
    }
  }

  @media screen and (min-width: 1280px) {
    .layout {
      padding-left: 100px;
    }
  }
</style>
